HideCommonUIForWebview()

function HideCommonUIForWebview() {
  // URLSearchParamsが使えなければ何もしない
  // ポリフィルはこのあとのmain.jsで読むのでここでは使えない
  // とはいえ、IEではウェブビュー使うことはないので、ここではこれでいい
  // ※IEは推奨環境から外された
  if (!window.URLSearchParams) {
    return
  }

  // ウェブビューのパラメータチェック
  const searchParams = new URLSearchParams(window.location.search)

  // ウェブビューパラメータが文字列の0なら共通UIを隠すクラス名をつける
  if (searchParams.get('webview') === '0') {
    document.documentElement.classList.add('in-webview')
  }

  // GETクエリで `ch` が `sp_` で始まっていたら、ヘッダー、フッターは簡易版を表示させる
  // 例 http://localhost:8080/?ch=sp_xxx
  if (/^sp_/.test(searchParams.get('ch'))) {
    document.documentElement.classList.add('-forceSimpleHeader')
    document.documentElement.classList.add('-forceSimpleFooter')
    document.documentElement.classList.add('-forceHideBreadcrumbs')
  }
}
